import { createRouter, createWebHistory } from 'vue-router'
import { getBroswer, getPath } from '@/utils'

const reload = () => !navigator.userAgent.includes('tvb-anywhere-webtv-seo-crawler') && window.location.reload()

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      redirect: { name: 'TvbAnywhere' }
    },
    {
      path: '',
      alias: [
        '/:language(en|sc|tc)?',
        '/:language(en|sc|tc)?/:platform?',
        '/mo/:language(en|sc|tc)?',
        '/mo/:language(en|sc|tc)?/:platform?'
      ],
      name: 'TvbAnywhere',
      component: () => import('@/layout/layout.vue').catch(() => reload()),
      redirect: { name: 'Home' },
      children: [
        {
          path: '',
          alias: ['programme', 'editorial'],
          name: 'Home',
          meta: { ad: { page: 'home' } },
          component: () => import('@/views/Home/home.vue').catch(() => reload())
        },
        {
          path: 'editorial/:editorial_group_id/:editorial_group_name?',
          name: 'editorialMore',
          meta: { ad: { page: 'ed' } },
          component: () =>
            import('@/views/EditorialMore/index.vue').catch(() => reload())
        },
        {
          path: 'vod/:library_id?/:attribute_id?/:name?', //name:library_name - attribute_name
          name: 'VodZone',
          meta: { ad: { page: 'main' } },
          component: () => import('@/views/VODZone/index.vue').catch(() => reload())
        },
        {
          path: 'channels/:library_id?/:library_name?',
          alias: 'channel/:library_id?',
          name: 'ChlZone',
          component: () => import('@/views/CHLZone/index.vue').catch(() => reload())
        },
        {
          path: 'epg/:channel_id?/:library_id?/:channel_name?',
          name: 'epg',
          meta: { ad: { page: 'epg' } },
          component: () => import('@/views/EPG/index.vue').catch(() => reload())
        },
        {
          path: 'programme/:programme_id/:episode_id?/:name', //name:programme_name - episode_name
          alias: ['programme/:programme_id/:programme_name?'],
          name: 'VodPlayer',
          meta: { ad: { page: 'player' } },
          component: () => import('@/views/Player/player.vue').catch(() => reload())
        },
        {
          path: 'channel/:channel_id/:library_id/:name?', // name:channel_name - library_name @/views/CHLPlayer/index.vue
          name: 'CHLPlayer',
          meta: { ad: { page: 'live' } },
          component: () => import('@/views/Player/player.vue').catch(() => reload())
        },
        {
          path: 'search',
          name: 'search',
          meta: { ad: { page: 'search' } },
          component: () => import('@/views/Search/index.vue').catch(() => reload())
        },
        {
          path: 'recently-watched',
          name: 'history',
          meta: { ad: { page: 'recentlywatched' } },
          component: () => import('@/views/History/index.vue').catch(() => reload())
        },
        {
          path: 'favourite',
          name: 'favourite',
          meta: { ad: { page: 'myfavorite' } },
          component: () =>
            import('@/views/Favorite/index.vue').catch(() => reload())
        },
        {
          path: ':type(about-us|tutorial|faq|faq-webview|contact-us|partners-dealers|terms-of-service|terms-of-services|privacy|privacy-notice-full|privacy-notice-overview|cookies-policy|payment-terms-and-conditions)',
          name: 'static',
          component: () => import('@/views/Static/index.vue').catch(() => reload()),
          meta: { noHF: false },
          beforeEnter: (to) => {
            to.meta.noHF = to?.params?.type === 'faq-webview' ? true : false
            if (to?.params?.type === 'terms-of-services') {
              return routeTo(to?.path, 'terms-of-service')
            }
          }
        },
        {
          path: 'subscribe',
          name: 'subscribe',
          component: () =>
            import('@/views/Static/Subscribe.vue').catch(() => reload())
        },
        {
          path: '404',
          redirect: { name: 'Error404' }
        },
        {
          path: '500',
          redirect: { name: 'Error500' }
        },
        {
          path: 'unsupported',
          redirect: { name: 'unsupported' }
        },
        {
          path: '404.html',
          name: 'Error404',
          meta: { noRobots: true },
          component: () => import('@/views/Error/404.vue').catch(() => reload())
        },
        {
          path: '500.html',
          name: 'Error500',
          meta: { noRobots: true },
          component: () => import('@/views/Error/500.vue').catch(() => reload())
        },
        {
          path: 'unsupported.html',
          name: 'unsupported',
          meta: { noRobots: true },
          component: () =>
            import('@/views/Error/unsupported.vue').catch(() => reload())
        },
        {
          path: ':pathMatch(.*)*',
          redirect: { name: 'Error404' }
        }
      ]
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'Error404' }
    }
  ],
  scrollBehavior(_, __, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ top: savedPosition?.top ?? 0 })
      }, 500)
    })
  }
})

const unsupportedList = ['VodPlayer', 'CHLPlayer']
router.beforeEach((to) => {
  if (getBroswer() === '' && unsupportedList.includes(to.name)) {
    return routeTo(to.path, 'unsupported.html')
  }
  if (getBroswer() !== '' && to.name === 'unsupported') {
    return getPath()
  }
  if (to.name === 'Error404' && !to.params?.language && to.redirectedFrom?.params?.language) {
    return routeTo(to.redirectedFrom?.path, '404.html')
  }
  if (to.name === 'Error500' && !to.params?.language && to.redirectedFrom?.params?.language) {
    return routeTo(to.redirectedFrom?.path, '500.html')
  }
})

const routeTo = (path, name) => {
  let [c, l] = path.split('/').filter((s) => s)
  if (['tc', 'sc', 'en'].includes(c)) l = c
  return `${c === 'mo' ? '/mo' : ''}/${l}/${import.meta.env.VITE_APP_PLATFORM}/${name || ''}`
}

export default router
