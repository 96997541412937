import request from '@/utils/request'
import { getDeviceInfo } from '@/utils/index'

/**
 * 获取设备地理ip
 * http://apisfm.qa.tvbanywhere.com.sg/docs/?token=NZz7KRGL4NlbssaHB6teuzVKhwkQ3sZF#/geoip/get_geoip_check_platform__platform_
 * @param {*} param0 platform 平台code
 * @returns
 */
export const getDevicesGeoIp = () => {
  return request({
    url: '/geoip/v2/check/platform/{{platform}}',
    method: 'get',
    baseURL: import.meta.env.VITE_U_API_BASE_URL
  })
}

export const luaApiCookies = (data) => {
  return request({
    url: '/api/cookie',
    baseURL: import.meta.env.VITE_LUA_API_BASE_URL,
    method: 'post',
    data
  })
}

/**
 * seo
 */
export const getMetaData = () => {
  return request({
    url: '/html/data/metadata.json',
    baseURL: import.meta.env.VITE_S_API_BASE_URL,
    method: 'get'
  })
}

/**
 * 获取 static page
 */
export const getWebpage = () => {
  return request({
    url: '/html/data/webpage_index.json',
    baseURL: import.meta.env.VITE_S_API_BASE_URL,
    method: 'get'
  })
}
export const getHtmlData = (url) => {
  return request({
    url,
    method: 'get'
  })
}
/**
 * 获取活动
 * section A “service plan (campaigns)”
 * section B “set top box (campaigns)”
 * https://apiboss.qa.tvbanywhere.com.sg/frontend/purchasable_items/premium_poster_for_guest
 */
export const getCampaigns = (data) => {
  return request({
    url: '/frontend/purchasable_items/premium_poster_for_guest',
    baseURL: import.meta.env.VITE_BOSS_API_BASE_URL,
    method: 'post',
    data
  })
}

/**
 * 獲取廣播消息
 * broadcast_id 模板ID
 * URL	The broadcast name, free-text
 * CUSTOMER_TYPE	“guest”, “free”, “paid” 用户类型
 * BLACK_LIST	Country Black List 国家黑名单
 * WHITE_LIST	Country White List
 * START_TIME	Available start time 开始时间 2023-07-01T15:00:00.000Z
 * END_TIME	Available end time 结束时间 2023-10-01T15:15:00.000Z
 * PLATFORM	“android”, “ios”, “smart_tv”, “stb”, “launcher”, “androidtvstb”, “ctm_stb”, “webtv” 平台类型
 * PUBLISHED_VERSION	Published version number of the message header 发布版本号
 * STATUS	“enable”, “disable”, “locked”, “soft-locked”. Please just update when the status = “enable”. 状态
 * BROADCAST_HISTORYS	The following
 *
 *   VERSION_ID	The message body content’s version ID. 消息ID
 *              A message may have been updated, so
 *              a)	For “dismissible” message, client side needs to keep track whether the “retrieved” VERSION_ID is different from previous ONE.
 *                  对于“dismissive”消息，客户端需要跟踪“检索”的VERSION_ID是否与前一个不同。
 *              b)	For “un-dismissible” message, client side just needs to “retrieve” the latest ONE in case the page is refreshed.
 *                  对于“不可忽略”的消息，客户端只需要“检索”最新的一个，以防页面刷新。
 *   REF_TYPE	“broadcast”
 *   OTHER_FLAGS	“dismissible”: broadcast message (once) 广播消息(一次)
 *                “un-dismissible”: maintenance mode (permanent) 维护模式(永久)
 *
 *   WEBPAGE_CONTENT
 *     LANGUAGE	“en”, “tc”, “sc” 语言
 *     PAGE_BODY	HTML content 内容
 */
export const getBroadcast = () => {
  return request({
    url: '/html/data/broadcast.json',
    baseURL: import.meta.env.VITE_S_API_BASE_URL,
    method: 'get'
  })
}

/**
 * 上报异常
 * @param {*} data
 * @returns
 */
export const reportException = (data = {}) => {
  // {
  //     "url": "string", 出現錯誤的頁面url
  //     "app_info": "string", 應用相關信息：版本、發布時間等，使用;隔開
  //     "device_info": "string", 設備信息：設備信息、系統信息
  //     "error_type": "info", 錯誤類型 info, warning, error
  //     "error": "string" 錯誤信息
  //   }
  if (
    ['googlebot', 'spider', 'bot', 'whatsapp', 'facebook', 'skype'].some((s) =>
      navigator.userAgent.toLowerCase().includes(s)
    )
  ) {
    return Promise.reject('Requests from bot')
  } else {
    const deviceInfo = { userAgent: navigator.userAgent, device: getDeviceInfo()?.code }
    const navigatorConnection =
      navigator.connection || navigator.mozConnection || navigator.webkitConnection
    for (const key in navigatorConnection) {
      if (typeof navigatorConnection[key] !== 'function' && navigatorConnection[key]) {
        deviceInfo[key] = navigatorConnection[key]
      }
    }
    const request = () => Promise.reject({})
    return request({
      url: '/api/exception',
      method: 'post',
      headers: {
        'X-Session-Id': data.session_id || ''
      },
      data: {
        ...data,
        ...{
          app_info: `${window.appVersion};${window.appPublishTime}`,
          device_info: JSON.stringify(deviceInfo)
        }
      }
    })
  }
}
