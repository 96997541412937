/** English */
export default {
  layout: {
    language: 'language: {name}',
    loginBtn: 'Login/ Register now | Login / Register | Login Now',
    loginTip:
      'Please register / login as a member\n to access more content. | Please register / login as a member to access more content. | Please Register/ Login as a member to acess more content.',
    cancel: 'Cancel | Later',
    broadcastTitle: 'News update',
    broadcastBtn: 'OK',
    broadcastLabel: 'Do not show this message again.',
    areaNotice: {
      message:
        'This page is not for your current locations.\nDo you want to change to your current location?',
      jump: "Let's go!",
      messageMo:
        'This page is not applicable to your current location. \nDo you want to go back to the Homepage of your current location?',
      jumpMo: "Let's go to my homepage"
    },
    tutorial: 'Tutorial',
    fqa: 'FAQ',
    downloadApp: 'Download App',
    contactUs: 'Contact Us',
    infoSupport: 'Info / Support',
    setting: 'Setting'
  },
  home: {
    name: 'Home',
    more: 'More',
    errorPage: 'Enter the error page',
    seo: {
      title: () => 'TVB Anywhere | Overseas official website',
      description:
        'Bring you the latest and most popular TVB TV series, variety show, movies and LiveTV, as well as exclusive behind-the-scenes highlights, interviews, documentaries and other exclusive content, so that you can enjoy the movie-watching experience immersively!'
    },
    episode1: 'Episode {n}',
    episode2: 'Updated to Episode {n}',
    episode3: 'All {n} Episode | All {n} Episodes',
    episode4: 'Updated to {n}',
    episode5: 'All {n} Periods',
    downloadDesc: 'Download our app now to enjoy more features!'
  },
  vodplayer: {
    name: 'VOD Zone'
  },
  vodzone: {
    name: 'VOD Zone',
    more: 'More',
    less: 'Less',
    seo: {
      description: 'Channel with 7 days catch up function / 3 hours catch up function'
    }
  },
  chlzone: {
    name: 'Channel Zone',
    empty: 'There are no available channels.'
  },
  player: {
    recommend: 'Recommend',
    fullScreen: 'EPG/Catch-up',
    cast: 'Casts',
    as: 'as',
    episodes: 'Episodes',
    playing: 'Playing',
    nowplaying: 'Now Playing',
    episodeContents: 'This Episode synopsis',
    synopsis: 'Programme Info',
    openinapp: 'Open in app',
    basicmember: 'Member',
    copied: 'Copied',
    hour: ' H',
    day: ' D',
    error: {
      nologin: 'Login / Register',
      more: 'Learn More',
      free: 'Watching latest free episode',
      chlEmpty: 'There are no available channels.'
    },
    lastDayToWatch: 'Last day to watch ',
    liveDescription: ' = 7 days  / 3 hours catch up'
  },
  epg: {
    name: 'EPG',
    tip: 'Only the channels with EPG will be displayed',
    live: 'Live',
    playing: 'Playing catch up now',
    today: 'Today',
    seo: {
      title: () => 'LIVE | TVB Anywhere'
    }
  },
  error: {
    title404: 'Page not found',
    des404:
      'Sorry, the page you were looking for may not be available in your current location or does not exist.',
    title500: 'Internal Server Error',
    des500:
      'Sorry, there was an error and your request cannot be completed. If the problem persists, please contact cs@tvbanywhere.com. ',
    backHome: 'Return to home page',
    empty: 'Could not find relevant information',
    titleUnsup: 'Improve your browing experience',
    desUnsup:
      "You're using a web browser we don't support. Try one of the following browsers to have a better experience."
  },
  setting: {
    title: 'Setting',
    video: {
      quality: 'Video Quality',
      auto: 'Auto',
      high: 'High',
      medium: 'Medium',
      low: 'Low'
    },
    audio: {
      channel: 'Audio Channel',
      cantonese: 'Cantonese',
      mandarin: 'Mandarin',
      english: 'English',
      thai: 'Thai',
      vietnamese: 'Vietnamese',
      audio1: 'Audio 1',
      audio2: 'Audio 2'
    },
    subtitle: {
      language: 'Subtitle Language',
      simplifiedChinese: 'Simplified Chinese',
      traditionalChinese: 'Traditional Chinese',
      bahasaIndonesia: 'Bahasa Indonesia',
      english: 'English',
      vietnamese: 'Vietnamese',
      off: 'Off'
    },
    automaticallyPlayNextEpisode: 'Automatically play next episode',
    fontSize: 'Font size'
  },
  search: {
    search: 'Search',
    history: 'Search History',
    hot: 'Hot Search',
    audio: 'Search Video by Audio Language',
    subtitle: 'Search Video by Subtitle Language',
    channels: 'Channels',
    programmes: 'Programmes',
    recommendation: 'Recommendation',
    empty: 'No result found',
    seo: {
      title: () => 'Search TVB Programme / Artist | TVB Anywhere',
      description: 'Search artiste name, title name, audio or subtitle language'
    }
  },
  history: {
    name: 'Recently Watched',
    empty: 'No viewing history found!',
    delete: 'DELETE',
    select: 'SELECT ALL',
    deselect: 'DESELECT ALL',
    more: 'See more',
    seo: {
      title: () => 'Recently Watched | TVB Anywhere',
      description: 'Recently Watched'
    }
  },
  favourite: {
    name: 'My Favourite',
    empty: 'You have not added any Favourites!',
    delete: 'DELETE',
    select: 'SELECT ALL',
    deselect: 'DESELECT ALL',
    more: 'See more',
    added: 'Added to My Favourites',
    removed: 'Removed from My Favourites',
    toast: {
      added: 'Added to My Favourites',
      removed: 'Removed from My Favourites'
    },
    down: 'Unavailable',
    seo: {
      title: () => 'My Favourite | TVB Anywhere',
      description: 'My Favourite'
    }
  },
  static: {
    privacy: 'Privacy',
    privacyNoticeFull: 'Privacy Notice (EU)',
    privacyNoticeOverview: 'Data Privacy Overview (EU)',
    cookiesPolicy: 'Cookies Policy',
    cookiesSetting: 'Cookies Setting',
    aboutUs: 'About us',
    tutorial: 'Tutorial',
    tutorialStb: 'Set Top Box',
    tutorialApp: 'Cross Platform',
    faq: 'FAQ',
    contactUs: 'Contact Us',
    partnersDealers: 'Partners and Dealers Contact',
    termsOfService: 'Terms of Service',
    paymentTermsAndConditions: 'Payment Terms And Conditions'
  },
  subscribe: {
    sectionA: {
      title: 'Enjoy an endless stream of \nwonderful dramas and variety shows anytime, anywhere',
      desc: 'Choose your service plan now',
      tip: 'Save over 15% with an annual subscription (compared to 12 consecutive monthly subscriptions)',
      month: 'month | {n} month | {n} months',
      year: 'year'
    },
    sectionB: {
      add: 'Additional\npurchase',
      btn: 'Subscribe now',
      click: 'Click',
      more: 'here to learn more'
    }
  }
}
