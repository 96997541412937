import axios from 'axios'
import axiosRetry from 'axios-retry'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'

const appName = import.meta.env.VITE_APP_NAME

const request = axios.create({
  timeout: 1000 * 10,
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})

// 判断一段时间内的接口是否结束
// 排除不相关的api
let blackList = ['/wcms/getMetaData', '/wcms/getBroadcast']
let numberOfAjaxCAllPending = 0
const isAjaxCAllPendingEnd = (config = {}) => {
  if (blackList.includes(config?.url)) return
  setTimeout(() => {
    numberOfAjaxCAllPending--
    if (numberOfAjaxCAllPending <= 0 && !document.querySelector(`[name="${appName}"]`)) {
      const tvbMeta = document.createElement('meta')
      tvbMeta.name = appName
      tvbMeta.content = 'true'
      document.querySelector('head').appendChild(tvbMeta)
    }
  }, numberOfAjaxCAllPending <= 0 ? 2000 : 500)
}

// 添加請求攔截器
request.interceptors.request.use(
  (config) => {
    if (!blackList.includes(config.url)) numberOfAjaxCAllPending++
    try {
      const appStore = useAppStore()
      const userStore = useUserStore()
      const countryCode = appStore?.config?.geoInfo?.country || ''
      const languageCode = appStore?.currentLanguage?.code || ''
      const platform = import.meta.env.VITE_APP_PLATFORM || ''
      let [c, l] = location.pathname.split('/').filter((s) => s)
      if (['tc', 'sc', 'en'].includes(c)) l = c
      config.url = config.url
        .replaceAll('{{country_code}}', countryCode)
        .replaceAll('{{language}}', l || languageCode)
        .replaceAll('{{platform}}', platform)
        .replaceAll('{{customer_type}}', userStore?.userCustomer?.customer_stage || 'guest')
        .replaceAll('{{customer_stage}}', userStore?.userCustomer?.customer_stage || 'guest')
      const token = userStore.token
      const guestToken = userStore.guestToken
      if (config.headers['Tvb-Auth-Session-Token'])
        config.headers['Tvb-Auth-Session-Token'] = token || guestToken
      if (config.baseURL !== import.meta.env.VITE_API_BASE_URL) {
        if (token) config.headers.authorization = `Bearer ${token}`
        else if (guestToken) config.headers.authorization = `Bearer ${guestToken}`
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 添加響應攔截器
request.interceptors.response.use(
  (response) => {
    isAjaxCAllPendingEnd(response?.config)
    return Promise.resolve(response?.data || {})
  },
  (error) => {
    isAjaxCAllPendingEnd(error?.response?.config)
    if (error?.response?.data?.messages?.display) {
      const appStore = useAppStore()
      const countryCode = appStore?.config?.geoInfo?.country || ''
      const languageCode = appStore?.currentLanguage?.code || ''
      error?.response?.data?.messages?.display?.forEach((d) => {
        if (d.language.toLocaleLowerCase() === countryCode.toLocaleLowerCase()) {
          error.response.data.messages.message = d.messages
        }
      })
      if (!error.response.data.messages.message) {
        error?.response?.data?.messages?.display?.forEach((d) => {
          if (d.language.toLocaleLowerCase() === languageCode.toLocaleLowerCase()) {
            error.response.data.messages.message = d.message
          }
        })
      }
      if (!error.response.data.messages.message) {
        error.response.data.messages.message =
          error?.response?.data?.messages?.display?.[0]?.message
      }
    }
    return Promise.reject(error)
  }
)

/** 重试处理 */
/** https://www.npmjs.com/package/axios-retry */
axiosRetry(request, {
  retries: 3,
  shouldResetTimeout: true,
  retryCondition: (error) => {
    return (
      axiosRetry.isNetworkOrIdempotentRequestError(error) || error?.message?.includes('timeout')
    )
  }
})

export default request
